<template>
  <div class="latest-nws">
    <div class="pst-block">
      <div class="pst-block-head">
        <h2 class="title-4"><strong>Галерея</strong></h2>
      </div>
      <div class="pst-block-main">
        <div class="col-row">
          <div :key="index" class="col-half" v-for="(gallery, index) in $store.state.gallery.data">
            <article class="post post-tp-8">
              <figure v-if="gallery.img_gallery.length">
                <router-link :to="{name: 'open_gallery', params: {id: gallery.id}}">
                  <img :alt="gallery.name" :src="gallery.img_gallery[0].get_img.media_server_url + gallery.img_gallery[0].get_img.url" class="adaptive" style="object-fit: cover; height: 242px; width: 345px;"/>
                </router-link>
              </figure>
              <figure v-else>
                <router-link :to="{name: 'open_gallery', params: {id: gallery.id}}">
                  <img :alt="gallery.name" :src="$store.state.system_data.base_url + '/img/placeholder.png'" class="adaptive" style="object-fit: cover; height: 242px; width: 345px;"/>
                </router-link>
              </figure>
              <h3 class="title-5">
                <router-link :to="{name: 'open_gallery', params: {id: gallery.id}}">{{ gallery.name }}</router-link>
              </h3>
              <div class="meta-tp-2">
                <div class="date"><span>{{ gallery.updated_at | human_date }}</span></div>
              </div>
            </article>
          </div>
        </div>
        <nav class="page-pagination" v-if="$store.state.gallery.total > $store.state.gallery.per_page">
          <button :class="'page-arrows' + ($store.state.gallery.prev_page_url === null ? ' disabled' : '')" @click="parsePage($store.state.gallery.prev_page_url)" type="button">
            <svg fill="none" height="14" viewBox="0 0 18 14" width="18" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 6.99997L1.43944 7.49825L0.996529 6.99997L1.43945 6.50169L2 6.99997ZM7.89395 1.49828L2.56055 7.49825L1.43945 6.50169L6.77285 0.501722L7.89395 1.49828ZM2.56055 6.5017L7.89395 12.5017L6.77284 13.4983L1.43944 7.49825L2.56055 6.5017ZM2 6.24997H18V7.74997H2V6.24997Z" fill="#0176FF"></path>
            </svg>
          </button>
          <ul class="pagination">
            <li :class="'page-item' + ($store.state.gallery.current_page === 1 ? ' active' : '')">
              <button :class="'page-link' + ($store.state.gallery.current_page === 1 ? ' disabled' : '')" @click="parsePage($store.state.gallery.current_page === 1 ? null : $store.state.system_data.base_url + '/api/mo?page=' + $store.state.gallery.one)" type="button">
                {{ $store.state.gallery.one }}
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Gallery',
  methods: {
    async parsePage (url) {
      if (url !== null) {
        await this.$store.dispatch('homePage', url);
      }
    }
  }
};
</script>

<style scoped>

</style>
