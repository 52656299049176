<template>
  <div class="editors-pst">
    <div class="pst-block spst-slider">
      <div class="pst-block-head">
        <h2 class="title-4"><strong>Достопримечательности</strong></h2>
      </div>
      <div class="pst-block-main">
        <div class="col-row">
          <article :key="index" class="post post-tp-5 col-one-third" v-for="(item, index) in $store.state.attractions.data">
            <figure v-if="item.img_gallery.length">
              <router-link :to="{name: 'openAttractions', params: {id: item.id}}">
                <img :alt="item.name" :src="item.img_gallery[0].get_img.media_server_url + item.img_gallery[0].get_img.url" class="adaptive" style="height: 150px; width: 224px; object-fit: cover"/>
              </router-link>
            </figure>
            <figure v-else>
              <router-link :to="{name: 'openAttractions', params: {id: item.id}}">
                <img :alt="item.name" :src="$store.state.system_data.base_url + '/img/placeholder.png'" class="adaptive" style="height: 150px; width: 224px; object-fit: cover"/>
              </router-link>
            </figure>
            <h3 class="title-5">
              <router-link :to="{name: 'openAttractions', params: {id: item.id}}">{{ item.name }}</router-link>
            </h3>
          </article>
        </div>
      </div>
      <div class="pst-block-foot">
        <router-link :to="{name: 'attractions'}">Больше достопримечательностей</router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Attractions',
};
</script>

<style scoped>

</style>
