<template>
  <div class="popular-pst">
    <div class="pst-block">
      <div class="pst-block-head">
        <h2 class="title-4"><strong>Полезное</strong></h2>
        <div class="filters">
          <ul class="filters-list-1 xs-hide">
            <li>
              <a :class="active_category === null ? 'switch-active' : ''" @click.prevent="setActiveNews(null)" href="#">Все</a>
            </li>
            <li :key="index" v-for="(category, index) in $store.state.categories_partner.data">
              <a :class="active_category === category.id ? 'switch-active' : ''" @click.prevent="setActiveNews(category.id)" href="#">{{ category.title }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="pst-block-main">
        <div class="col-row">
          <!--          <div class="full">-->
          <template v-for="(item, index) in categoriesPartner.slice(0, 8)">
            <article :key="index" class="post post-tp-6 half">
              <figure v-if="item.img !== null">
                <a :href="item.url" target="_blank">
                  <img :alt="item.name" :src="item.media_server_url !== null ? item.media_server_url + item.img : $store.state.media_server_url + item.img" class="adaptive" style="height: 85px; width: 115px; object-fit: cover"/>
                </a>
              </figure>
              <figure v-else>
                <a :href="item.url" target="_blank">
                  <img :alt="item.title" :src="$store.state.system_data.base_url + '/img/placeholder.png'" class="adaptive" style="height: 85px; width: 115px; object-fit: cover"/>
                </a>
              </figure>
              <h3 class="title-6">
                <a :href="item.url" target="_blank">{{item.name}}</a>
                <router-link :to="{ name: 'open_news', params: {id: item.id}}">{{ item.title }}</router-link>
              </h3>
            </article>
          </template>
          <h3 v-if="!categoriesPartner.length" class="title-4">Нет добавленных ссылок</h3>
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'News',
  data () {
    return {
      active_category: null,
      categoriesPartner: []
    };
  },
  created () {
    this.setActiveNews(null);
  },
  beforeMount () {
    this.getAllCategories();
  },
  methods: {
    getAllCategories () {
      this.categoriesPartner = [];
      this.$store.state.categories_partner.data.forEach(item => {
        item.get_partner.forEach(i => {
          this.categoriesPartner.push(i);
        });
      });
    },
    setActiveNews (active_category = null) {
      this.active_category = active_category;
      let data = this.$store.state.categories_partner.data;
      if (active_category === null) {
        this.getAllCategories();
      } else {
        data.forEach((item) => {
          if (item.id === active_category) {
            this.categoriesPartner = item.get_partner;
          }
        });
      }
      /*data.forEach((item) => {
        if (active_category === null) {
          item.isHidden = false;
        } else {
          item.isHidden = active_category === item.category_id;
        }
      });*/
      this.$store.state.categories_partner.data = data;
    }
  }
};
</script>
