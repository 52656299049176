<template>
  <div class="recent-nws">
    <div class="pst-block">
      <div class="pst-block-head toggler-fix">
        <h2 class="title-4">Полезные ссылки</h2>
        <div :class="`switch-toggler ${active ? 'active' : ''}`" @click="active = !active">
          <div class="switch-toggler__static">Федеральные</div>
          <div class="switch-toggler__static">Региональные</div>
        </div>
      </div>
      <div class="pst-block-main">
        <div class="tab_item js-csp-block js-tab-slider" id="tab_news_all">
          <div v-if="!active">
            <article :key="index" class="post post-tp-9" v-for="(link, index) in $store.state.partners">
              <figure>
                <a :href="link.url" target="_blank">
                  <img :alt="link.name" :src="`${link.media_server_url}/${link.img}`" class="adaptive" style="object-fit: contain; height: 85px; width: 115px;">
                </a>
              </figure>
              <h3 class="title-6">
                <a :href="link.url" target="_blank">{{ link.name }}</a>
              </h3>
            </article>
          </div>
          <div v-else>
            <article :key="index" class="post post-tp-9" v-for="(link, index) in $store.state.links">
              <figure>
                <a :href="link.url" target="_blank">
                  <img :alt="link.name" :src="$store.state.system_data.base_url + `/img/${link.img}`" class="adaptive" style="object-fit: contain; height: 85px; width: 115px;">
                </a>
              </figure>
              <h3 class="title-6">
                <a :href="link.url" target="_blank">{{ link.name }}</a>
              </h3>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Links',
  data () {
    return { active: !this.$store.state.links.length };
  }
};
</script>
<style scoped>
</style>
