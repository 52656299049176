<template>
  <div class="poling-widget">
    <div class="pst-block">
      <div class="pst-block-head">
        <h2 class="title-4"><strong>Услуги</strong></h2>
      </div>
      <div class="pst-block-main">
        <div class="poling-block">
          <!--<h5 class="title-9">Основные услуги</h5>-->
          <ul>
            <li :key="index" class="post post-tp-6" v-for="(item, index) in $store.state.services.data">
              <h6 class="title-6">
                <router-link :to="{name: 'open_service', params: {id: item.id}}">{{ item.name }}</router-link>
              </h6>
            </li>
          </ul>
          <router-link :to="{name:'services'}" class="btn-1" v-if="$store.state.services.data.length > 5">Показать все</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Services'
};
</script>

<style scoped>

</style>
