<template>
  <div v-if="$store.state.magazine.data.length">
    <div class="pst-block-head">
      <h2 class="title-4"><strong>Газета</strong></h2>
    </div>
    <div class="sb-banner">
      <div class="banner-inner">
        <img :alt="$store.state.magazine.data[0].name" :src="$store.state.magazine.data[0].img_articles[0].get_img.media_server_url + $store.state.magazine.data[0].img_articles[0].get_img.url" class="adaptive" style="width: 320px;  height: 270px; object-fit: cover" v-if="$store.state.magazine.data[0].img_articles.length"/>
        <img :alt="$store.state.magazine.data[0].name" :src="$store.state.system_data.base_url + '/img/placeholder.png'" class="adaptive" style="width: 320px;  height: 270px; object-fit: cover" v-else/>
        <div class="banner-overlay">
          <!--<h5 class="title-11">The Best <br>Magazine &amp; Blog <br>Theme</h5>-->
          <router-link :to="{name: 'open_magazine', params: {id: $store.state.magazine.data[0].id} }" class="btn-2">Перейти</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Magazine'
};
</script>

<style scoped>

</style>
