<template>
  <div class="main-content" style="padding: 10px 0;">
    <trending v-if="$store.state.school.category_id !== 9 && $store.state.news.length > 0"/>
    <popular-news v-if="$store.state.news.length > 0"/>
    <div class="section">
      <ClientOnly>
        <WidgetOG :html="widget" v-if="mounted && widget" style="margin-bottom: 20px; margin-top: -20px"/>
      </ClientOnly>
      <div class="row">
        <div class="content">
          <news v-if="$store.state.news.length > 0"/>
          <!--          <map/>-->
          <attractions v-if="$store.state.attractions.data.length > 0"/>
          <projects v-if="$store.state.projects.data.length > 0"/>
        </div>
        <aside class="side-bar"
               v-if="($store.state.about && $store.state.about.nameDirector) || $store.state.news.length > 0 || $store.state.attractions.data.length > 0">
          <!--          <weather/>-->
          <head-component v-if="$store.state.about && $store.state.about.nameDirector"/>
          <services v-if="$store.state.services.data.length > 0"/>
          <magazine v-if="$store.state.magazine.data.length > 0"/>
        </aside>
      </div>
    </div>
    <posters v-if="$store.state.posters.data.length > 0"/>
    <div class="section">
      <div class="row">
        <div class="content">
          <best-of-us v-if="$store.state.bests.data.length > 0"/>
          <gallery v-if="$store.state.gallery.data.length > 0"/>
        </div>
        <aside class="side-bar">
          <links v-if="$store.state.links.length > 0 || $store.state.partners.length > 0"/>
        </aside>
      </div>
    </div>
  </div>
</template>
<script>
import Gallery from "../components/Gallery.vue";
import Links from "../components/Links.vue";
import Attractions from "../components/Attractions.vue";
import HeadComponent from "../components/HeadComponent.vue";
import Projects from "../components/Projects.vue";
import Trending from "../components/Trending.vue";
import News from "../components/News.vue";
import PopularNews from "../components/PopularNews.vue";
import BestOfUs from "../components/BestOfUs.vue";
import Services from "../components/Services.vue";
import Posters from "../components/Posters.vue";
import Magazine from "../components/Magazine.vue";
import Map from "../components/Map.vue";
import Weather from "../components/Weather.vue";
import WidgetOG from "components/WidgetOG.vue";
import ClientOnly from "vue-client-only";

export default {
  name: "Home",
  components: {
    ClientOnly,
    WidgetOG,
    Weather,
    Map,
    Magazine,
    Posters,
    Services,
    BestOfUs,
    PopularNews,
    News,
    Trending,
    Projects,
    HeadComponent,
    Attractions,
    Links,
    Gallery
  },
  data () {
    return {
      mounted: true
    };
  },
  async asyncData (context) {
    await context.store.dispatch("homePage");
  },
  mounted () {
    this.mounted = true;
  },
  computed: {
    widget () {
      return this.$store.state.about.widgetOG;
    },
  },
  metaInfo () {
    return this.$seo(this.$store.state.about ? this.$store.state.about.nameSchool : "Не задано название");
  }
};
</script>
