<template>
  <div class="main-posts-1 pt-3">
    <div class="mp-section">
      <div class="two-thirds sm-full">
        <div class="main-slider-2">
          <div class="js-main-slider-2" ref="js_main_slider_2">
            <div :key="index" class="slide" v-for="(item, index) in $store.state.news">
              <article class="post post-tp-1">
                <figure v-if="item.img_news.length">
                  <router-link :to="{ name: 'open_news', params: {id: item.id}}">
                    <img :alt="item.title" :src="item.img_news[0].get_img.media_server_url + item.img_news[0].get_img.url" class="adaptive" style="height:471px; width:760px;object-fit: cover"/>
                  </router-link>
                </figure>
                <div class="ptp-1-overlay">
                  <div class="ptp-1-data">
                    <h3 class="title-1">
                      <router-link :to="{ name: 'open_news', params: {id: item.id}}">{{ item.title }}</router-link>
                    </h3>
                    <div class="meta-tp-1">
                      <div class="ptp-1-date">
                        <router-link :to="{ name: 'open_news', params: {id: item.id}}">{{ item.updated_at | human_date }}</router-link>
                      </div>
                    </div>
                    <router-link :to="{ name: 'open_news', params: {id: item.id}}" class="read-tp-1"><span>Подробнее</span> <span class="arr-right-light-ic"><i></i></span></router-link>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <div class="ms-navs" ref="ms_navs">
            <div class="slide-count" ref="slide_count">
              <span class="current"></span> из
              <span class="total"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="one-third sm-hide">
        <div class="trend-pst-slider">
          <div class="trpst-block">
            <div class="trpst-block-head">
              <h2 class="title-4"><strong>Популярные</strong> новости</h2>
            </div>
            <div class="trpst-block-main">
              <div class="js-trend-pst-slider">
                <div>
                  <article :key="index" class="post post-tp-3" v-for="(item, index) in $store.state.news">
                    <template v-if="index <= 2">
                      <figure v-if="item.img_news.length">
                        <router-link :to="{ name: 'open_news', params: {id: item.id}}">
                          <img :alt="item.title" :src="item.img_news[0].get_img.media_server_url + item.img_news[0].get_img.url" class="adaptive" style="height: 98px; width: 126px; object-fit: cover"/>
                        </router-link>
                      </figure>
                      <figure v-else>
                        <router-link :to="{ name: 'open_news', params: {id: item.id}}">
                          <img :alt="item.title" :src="$store.state.system_data.base_url + '/img/placeholder.png'" class="adaptive" style="height: 98px; width: 126px; object-fit: cover"/>
                        </router-link>
                      </figure>
                      <h3 class="title-3">
                        <router-link :to="{ name: 'open_news', params: {id: item.id}}">{{ `${item.title.substr(0, 60)}${item.title.length > 70 ? '...' : ''}` }}</router-link>
                      </h3>
                      <div class="date-tp-2">{{ item.updated_at | human_date }}</div>
                    </template>
                  </article>
                </div>
              </div>
            </div>
            <div class="trpst-block-foot">
              <router-link :to="{ name: 'news' }">
                <span class="more-txt">Перейти ко всем новостям</span><span class="arr-right-dark-ic"><i></i></span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery';

export default {
  name: 'PopularNews',
  data () {
    return {
      Slick: null
    };
  },
  beforeMount () {
    this.Slick = require('slick-carousel');
  },
  mounted () {
    let slick = this.Slick;
    var mainSlider = this.$refs.js_main_slider_2;
    var slideCount = this.$refs.slide_count;
    var slideNumCurrent = $(slideCount).find('.current');
    var slideNumTotal = $(slideCount).find('.total');

    $(mainSlider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      var i = (currentSlide ? currentSlide : 0) + 1;
      slideNumCurrent.text(i);
      slideNumTotal.text(slick.slideCount);
    });
    $(mainSlider).slick({
      speed: 600,
      autoplay: true,
      appendArrows: this.$refs.ms_navs,
      prevArrow: '<div class="prev"><i class="ms-prev"></div>',
      nextArrow: '<div class="next"><i class="ms-next"></div>'
    });
  }
};
</script>
<style lang="scss">
.slick-list {
  overflow: hidden;
}

.slick-track {
  display: flex;
}
</style>
