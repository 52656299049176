<template>
  <div class="trending-posts-line">
    <div class="container" style="display: flex;align-items: center;">
      <div class="trending-line">
        <div class="trending-now"><a href="/rule/10"><strong>Реестр муниципального имущества</strong></a></div>
      </div>
      <div class="trending-line" v-if="$store.state.about">
        <div class="trending-now"><a href="/rule/11"><strong>Имущественная поддержка МСП</strong></a></div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'Trending',
  data () {
    return {
      Slick: null
    };
  },
  beforeMount () {
    this.Slick = require('slick-carousel');
  },
  mounted () {
    let slider = this.$refs.slider;
    $(slider).slick({
      fade: true,
      speed: 600,
      autoplay: true,
      appendArrows: $('.tl-slider-control'),
      prevArrow: '<span class="arr-left-dark-ic tls-prev"><i></i></span>',
      nextArrow: '<span class="arr-right-dark-ic tls-next"><i></i></span>'
    });
  }
};
</script>

<style scoped>

</style>
