<template>
  <div class="ltst-reviews">
    <div class="pst-block">
      <div class="pst-block-head">
        <h2 class="title-4"><strong>{{$store.state.school.category_id === 9 ? 'Директор' : 'Глава'}}</strong></h2>
      </div>
      <div class="pst-block-main">
        <div class="js-csp-block">
          <div>
            <figure class="full-img">
              <a v-if="$store.state.about.img_director_small.length">
                <img :alt="`${$store.state.about.surnameDirector} ${$store.state.about.nameDirector} ${$store.state.about.patronymicDirector}`" :src="$store.state.about.img_director_small[0].get_img.media_server_url + $store.state.about.img_director_small[0].get_img.url" class="adaptive" style="width:360px; height:245px; object-fit:cover">
              </a>
              <a v-else>
                <img :alt="`${$store.state.about.surnameDirector} ${$store.state.about.nameDirector} ${$store.state.about.patronymicDirector}`" :src="$store.state.system_data.base_url + '/img/placeholder.png'" class="adaptive" style="width:360px; height:245px; object-fit:cover">
              </a>
            </figure>
            <article class="sbr-dots">
              <h3 class="title-5">
                <a href="">{{ `${$store.state.about.surnameDirector} ${$store.state.about.nameDirector} ${$store.state.about.patronymicDirector}` }}</a>
                <p class="p">{{ $store.state.about.aboutDirector ? $store.state.about.aboutDirector.substring(0, 200) + ($store.state.about.aboutDirector.length> 200 ? '...' : '') : ''}}</p>
              </h3>
            </article>
          </div>
        </div>
      </div>
      <div class="pst-block-foot">
        <router-link :to="{name: 'head_page'}">Подробнее</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeadComponent'
};
</script>

<style scoped>

</style>
