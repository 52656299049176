<template>
  <div class="design-pst">
    <div class="pst-block">
      <div class="pst-block-head">
        <h2 class="title-4"><strong>Карта</strong></h2>
        <div class="filters">
          <ul class="filters-list-1 xs-hide">
            <li><a class="active" href="">Все</a></li>
            <li><a href="">Проекты</a></li>
            <li><a href="">Больницы</a></li>
            <li><a href="">Достопримечательности</a></li>
            <li><a href="">Школы</a></li>
          </ul>
          <div class="filters-more">
            <div class="filters-btn js-fl-btn">
              <i class="li_settings"></i>
              <div class="filters-drop js-fl-block">
                <i class="arr"></i>
                <ul>
                  <li><a href="">Latest</a></li>
                  <li><a class="active" href="">Popular</a></li>
                  <li><a href="">Recent</a></li>
                  <li><a href="">Most comment</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pst-block-main">
        <div class="col-row">
          <div class="col-full">
            <article class="post post-tp-5">
              <figure>
                <a href="">
                  <img alt="Spectr News Theme" class="adaptive" height="242" src="https://static-maps.yandex.ru/1.x/?l=map&ll=47.74942800,41.45918600&size=600,250&z=14" width="345"/>
                </a>
                <div class="ptp-5-overlay">
                  <div class="ptp-5-data">
                    <a href="">
                      <i class="li_eye"></i>187
                    </a>
                    <a href="">
                      <i class="li_bubble"></i>187
                    </a>
                  </div>
                </div>
              </figure>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Map'
};
</script>
