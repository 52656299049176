<template>
  <div class="main-video-posts">
    <div class="video-posts-pane">
      <div class="vpst-block">
        <div class="vpst-block-head">
          <h2 class="title-4"><strong>Мероприятия</strong></h2>
        </div>
        <div class="vpst-block-main">
          <div class="js-video-pst-slider">
            <div class="vps-item">
              <div :key="index" class="one-quarter" v-for="(item, index) in $store.state.posters.data">
                <article class="post post-tpv-2">
                  <figure v-if="item.img_sections.length">
                    <router-link :to="{name: 'open_poster', params: {id: item.id}}">
                      <img :alt="item.title" :src="item.img_sections[0].get_img.media_server_url + item.img_sections[0].get_img.url" class="adaptive" style="height: 215px; width: 285px; object-fit: cover"/>
                    </router-link>
                  </figure>
                  <figure v-else>
                    <router-link :to="{name: 'open_poster', params: {id: item.id}}">
                      <img :alt="item.title" :src="$store.state.system_data.base_url + '/img/placeholder.png'" class="adaptive" style="height: 215px; width: 285px; object-fit: cover"/>
                    </router-link>
                  </figure>
                  <div class="ptp-1-overlay">
                    <div class="ptp-1-data">
                      <!--<span class="arr-left-light-sm-ic video-sm-ic"><i></i></span>-->
                      <h2 class="title-5">
                        <router-link :to="{name: 'open_poster', params: {id: item.id}}">{{ item.title }}</router-link>
                      </h2>
                      <div class="date-tp-2"><span>{{ item.updated_at | human_date }}</span></div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
        <div class="vpst-block-foot">
          <router-link :to="{name: 'posters'}">Все мероприятия</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Posters'
};
</script>

<style scoped>

</style>
