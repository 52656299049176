<template>
  <div class="latest-blg">
    <div class="pst-block spst-slider">
      <div class="pst-block-head">
        <h2 class="title-4"><strong>Гордость </strong> района</h2>
        <div class="filters">
          <div class="post-navs js-pst-navs">
            <a href="" class="prev pst-prev" ref="bestOfUsPrevArrow" style="margin-right: 5px;">
              <span class="arr-left-dark-ic"><i></i></span>
            </a>
            <a href="" class="next pst-next" ref="bestOfUsNextArrow">
              <span class="arr-right-dark-ic"><i></i></span>
            </a>
          </div>
        </div>
      </div>
      <div class="pst-block-main">
        <div class="col-row">
          <div ref="bestOfUsSlider">
            <div class="col-half" v-for="(item, index) in $store.state.bests.data" :key="index">
              <article class="post post-tp-7">
                <!--              <article class="post post-tp-7 col-half">-->
                <figure v-if="item.img_articles.length" style="width: 85px; height: 85px">
                  <router-link :to="{name: 'open_bests', params: {id: item.id} }">
                    <img :alt="item.name" :src="item.img_articles[0].get_img.media_server_url + item.img_articles[0].get_img.url" style="object-fit: cover; height: 100%; width: 100%; object-position: top;">
                  </router-link>
                </figure>
                <figure v-else>
                  <router-link :to="{name: 'open_bests', params: {id: item.id} }">
                    <img :alt="item.name" :src="$store.state.system_data.base_url + '/img/placeholder.png'" style="object-fit: contain; height: 80px; width: 68px">
                  </router-link>
                </figure>
                <h3 class="title-5">
                  <router-link :to="{name: 'open_bests', params: {id: item.id}}">{{ item.description.substr(0, 150) + (item.description.length > 150 ? '...' : '') }}</router-link>
                </h3>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div class="pst-block-foot">
        <router-link :to="{name: 'bests' }">Показать всех</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'BestOfUs',
  data () {
    return {
      slick: null
    };
  },
  beforeMount () {
    this.slick = require('slick-carousel');
  },
  mounted () {

    let slick = this.slick;
    let slider = this.$refs.bestOfUsSlider;
    let prev = this.$refs.bestOfUsPrevArrow;
    let next = this.$refs.bestOfUsNextArrow;

    $(slider).slick({
      slidesToShow: 2,
      slidesToScroll: 1,
      prevArrow: prev,
      nextArrow: next,
      responsive: [{
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
        }
      }]
    });

  }
};
</script>

<style scoped>

</style>
