<template>
  <div class="weather-widget">
    <div class="pst-block">
      <div class="pst-block-head">
        <h2 class="title-4"><strong><i class="li_location"></i> Ахты</strong></h2>
        <!--<a href="" class="arr-ic-3"><i class="fa fa-angle-down"></i></a>-->
      </div>
      <div class="pst-block-main">
        <div class="weather-block">
          <div class="temperature">
            <i class="weather-icon-clouds-flash-alt"></i>
            <span class="degrees-1">24<i class="degrees-ic-1"></i></span>
            <div class="day">Четверг</div>
          </div>
          <ul>
            <li>Дождь</li>
            <li>Влажность: 55%</li>
            <li>Ветер: 3км/ч СЗ</li>
            <!--<li>H 26 • L 26</li>-->
          </ul>
        </div>
        <hr class="pst-block-hr">
        <div class="weather-days">
          <ul class="weather-days-list">
            <li>
              <a href="">
                <span class="degrees-2">27<i class="degrees-ic-2"></i></span>
                <div class="day">Пт</div>
              </a>
            </li>
            <li class="active">
              <a href="">
                <span class="degrees-2">25<i class="degrees-ic-2"></i></span>
                <div class="day">Сб</div>
              </a>
            </li>
            <li>
              <a href="">
                <span class="degrees-2">26<i class="degrees-ic-2"></i></span>
                <div class="day">Вс</div>
              </a>
            </li>
            <li>
              <a href="">
                <span class="degrees-2">27<i class="degrees-ic-2"></i></span>
                <div class="day">Пн</div>
              </a>
            </li>
            <li>
              <a href="">
                <span class="degrees-2">23<i class="degrees-ic-2"></i></span>
                <div class="day">Вт</div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Weather'
};
</script>
