<template>
  <div class="pst-block">
    <div class="pst-block-head">
      <h2 class="title-4"><strong>Проекты</strong></h2>
    </div>
    <div class="mp-section">
      <div class="one-third sm-half xs-full" v-for="(item, index) in $store.state.projects.data" :key="index">
        <article class="post post-tp-2" style="padding: 10px;">
          <figure v-if="item.img_articles.length">
            <router-link :to="{ name: 'open_project', params: { id: item.id}}">
              <img :alt="item.name" :src="item.img_articles[0].get_img.media_server_url + item.img_articles[0].get_img.url" class="adaptive" style="height: 218px; width: 380px; object-fit: cover"/>
            </router-link>
          </figure>
          <figure v-else>
            <router-link :to="{ name: 'open_project', params: { id: item.id}}">
              <img :alt="item.name" :src="$store.state.system_data.base_url + '/img/placeholder.png'" class="adaptive" style="height: 218px; width: 380px; object-fit: cover"/>
            </router-link>
          </figure>
          <div class="ptp-1-overlay">
            <div class="ptp-1-data">
              <h2 class="title-29">
                <router-link :to="{ name: 'open_project', params: { id: item.id}}">{{ item.name }}</router-link>
              </h2>
              <div class="meta-tp-1">
                <div class="ptp-1-date">
                  <router-link :to="{ name: 'open_project', params: { id: item.id}}">{{ item.updated_at | human_date }}</router-link>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Projects'
};
</script>

<style scoped>

</style>
